import {create} from 'zustand'
import socket from '../socketio/socketio.js'

const usePackageStore = create((set) => ({
    packages: [],
    selectedPackages: [],
    packagesQueries: [],
    error: '',

    get: (previousId) => {
        socket.emit('packages.get', sessionStorage.token, previousId, (err, packages) => {
            if (err) {
                set({error: err})
                return
            }

            set({packages})
        })
    },
    getOne: (...args) => {
        socket.emit('packages.getOne', sessionStorage.token, ...args)
    },
    setSelected: (selected) => {
        set({selectedPackages: selected})
    },
    setQueries(packagesQueries) {
        set({packagesQueries, selectedPackages: []})
    }
}))


export default usePackageStore
