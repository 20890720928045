import React from 'react'
import Reflux from 'reflux-react-16'

import moment from 'moment'
import 'moment/locale/nl' // Sets locale globally?
moment.locale('en')

import UtilStore from '../../stores/UtilStore.js'
import UtilActions from '../../actions/UtilActions.js'
import dbCodes from '../../../server/dbCodes.js'

import {Alert, Card, IconButton, Colors, H5, H6, S1, P, Row, Column} from '../../components/UI/index.js'
import _ from 'lodash'
import LoadingPage from '../../components/UI/LoadingPage.js'

const styles = {
    h3: {
        margin: 2,
        color: '#1F2D3D',
        fontFamily: 'arial,helvetica,sans-serif',
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 1.15
    },
    h4: {
        margin: 2,
        color: '#414141',
        fontFamily: 'arial,helvetica,sans-serif',
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 1.15
    },
    p: {
        margin: 2,
        color: '#3b3f44',
        fontFamily: 'arial,helvetica,sans-serif',
        fontSize: 16,
        lineHeight: 1.15

    }
}

const statusIconMap = {
    car: 'mdi mdi-van-utility',
    'cargo-bike': 'mdi mdi-bicycle-cargo',
    reindeer: 'mdi mdi-reindeer'
}

export default class TrackTrace extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            settings: null,
            order: null,
            loading: false
        }

        this.store = UtilStore
    }

    componentDidMount() {
        const {trackTrace} = this.props.match.params
        const {addressIndex} = this.props.match.params

        document.title = `Track & Trace ${trackTrace}`

        UtilActions.trackTrace(trackTrace, addressIndex, (err, order, settings) => {
            if (!err) {
                this.setState({order, settings})
            } else {
                this.props.history.push('/track&trace', {notFound: true, trackTrace})
            }
        })
    }

    onClickViewImage(data) {
        const w = window.open('about:blank')
        const image = new Image()
        image.src = data
        setTimeout(() => {
            w.document.write(image.outerHTML)
        }, 0)
    }

    render() {
        const {order, settings, loading} = this.state

        if (loading) {
            return <LoadingPage/>
        }

        if (!order || !settings) {
            return null
        }

        const address = order.address

        let progress = order.status === dbCodes.status.onderweg() ? 3 : (!address.hasArrived && address.estimatedTimeOfArrival) ? 2 : 1
        let message = ''
        let dateHeader = ''
        let displayDate = moment(order.date).locale('nl').format('dddd DD MMMM')
        let displayTime = ''
        let info = ''

        let deliveryOptionsLine = ''

        if (address.signatureRequired) {
            deliveryOptionsLine += 'Er is een handtekening nodig voor ontvangst.\n'
        }
        if (address.statedAddressOnly && !address.mailboxDelivery) {
            deliveryOptionsLine += 'De zending wordt alleen aangeboden bij geadresseerde.\n'
        }
        if (address.mailboxDelivery) {
            deliveryOptionsLine += 'De zending past door de brievenbus.\n'
        }
        if (address.minimumAge) {
            deliveryOptionsLine += `De ontvanger dient ouder te zijn dan ${address.minimumAge} jaar.\n`
        }
        if (address.idCheck) {
            deliveryOptionsLine += 'Een legitimatiebewijs is nodig voor ontvangst.\n'
        }
        if (address.verificationCode) {
            deliveryOptionsLine += 'Voor ontvangst is een bezorgcode vereist.\n'
        }


        let estimatedTimeOfArrival = `tussen ${address.startTime.replace(':', '.')} en ${address.endTime.replace(':', '.')} uur`
        let timeOfArrival = ''

        const timeFrame = settings.timeFrame || 60

        if (address.estimatedTimeOfArrival) {
            estimatedTimeOfArrival = `tussen ${moment(address.estimatedTimeOfArrival, 'HH:mm').subtract(parseInt(timeFrame/3), 'minutes').format('HH.mm')} en ${moment(address.estimatedTimeOfArrival, 'HH:mm').add(parseInt(timeFrame*(2/3)), 'minutes').format('HH.mm')} uur`
        }

        if (address.hasArrived && address.timeOfArrival) {
            timeOfArrival = `om ${address.timeOfArrival.replace(':', '.')} uur`
        }

        if (address.type === 'pickup') {
            let lastPickupAttempt

            order.history.map((entry) => {
                if (entry.type === 'pickup') {
                    lastPickupAttempt = entry
                }
            })
            const someColliCollected = lastPickupAttempt?.colli && _.some(lastPickupAttempt.colli, (collo) => collo.status === 'collected')


            const allColliNotHome = lastPickupAttempt?.colli && _.every(lastPickupAttempt.colli, (collo) => collo.status === 'notcollected' && collo.reason === 'nothome')
            const allColliNotCollected = lastPickupAttempt?.colli && _.every(lastPickupAttempt.colli, (collo) => collo.status === 'notcollected' && collo.reason !== 'nothome')

            if (address.hasArrived && someColliCollected) {
                progress = 4

                dateHeader = 'Je zending is opgehaald op'
                displayTime = timeOfArrival
            } else {
                if (address.hasArrived && allColliNotCollected) {
                    message = settings.pickupNotCompletedMessage
                }

                if (address.hasArrived && allColliNotHome) {
                    message = settings.pickupNotHomeMessage
                }

                if (address.hasArrived || order.status === dbCodes.status.verwacht()) {
                    dateHeader = 'Je zending wordt opgehaald op'
                    displayDate = 'Datum nog niet bekend'
                    displayTime = ''
                } else {
                    if (!address.estimatedTimeOfArrival) {
                        dateHeader = 'Je zending wordt opgehaald op'
                        displayTime = ''
                        info = deliveryOptionsLine
                    }

                    if (address.estimatedTimeOfArrival || order.status === dbCodes.status.onderweg()) {
                        dateHeader = 'Je zending wordt opgehaald op'
                        displayTime = estimatedTimeOfArrival
                        info = deliveryOptionsLine
                    }
                }
            }

            if (order.status === dbCodes.status.geannuleerd()) {
                message = settings.pickupCancelledMessage

                dateHeader = 'Je zending wordt opgehaald op'
                displayDate = 'Zending geannuleerd'
                displayTime = ''
            }
        } else {
            let lastDeliveryAttempt

            order.history.map((entry) => {
                if (entry.type === 'delivery') {
                    lastDeliveryAttempt = entry
                }
            })

            const someColliDelivered = lastDeliveryAttempt?.colli && _.some(lastDeliveryAttempt.colli, (collo) => collo.status === 'delivered')

            let nrNeighbor
            const deliveredAtNeighbors = lastDeliveryAttempt?.colli && _.some(lastDeliveryAttempt.colli, (collo) => {
                nrNeighbor = collo.nrNeighbor
                return collo.deliveredAtNeighbors
            })

            const deliveredInMailbox = lastDeliveryAttempt?.colli && _.some(lastDeliveryAttempt.colli, (collo) => collo.deliveredInMailbox)

            const allColliNotHome = lastDeliveryAttempt?.colli && _.every(lastDeliveryAttempt.colli, (collo) => collo.status === 'notdelivered' && collo.reason === 'nothome')
            const allColliNotDelivered = lastDeliveryAttempt?.colli && _.every(lastDeliveryAttempt.colli, (collo) => ['notdelivered', 'missing', 'refused'].includes(collo.status))
            const allColliManco = order?.addressColli?.every((collo) => collo.status === 'manco')

            if (allColliManco) {
                message = settings.deliveryMancoMessage
            } else if (address.hasArrived && someColliDelivered) {
                progress = 4

                dateHeader = 'Je zending is bezorgd op'
                displayTime = timeOfArrival

                if (deliveredAtNeighbors) {
                    info += `Je zending is bezorgd bij de buren op huisnummer ${nrNeighbor}\n.`
                }

                if (deliveredInMailbox) {
                    info += 'Je zending is bezorgd in de brievenbus.\n'
                }
            } else {
                if (allColliNotDelivered && progress === 1) {
                    if (order.retourId) {
                        message = settings.deliveryNotCompletedRetourMessage
                    } else {
                        message = settings.deliveryNotCompletedRescheduleMessage
                    }
                }

                if (allColliNotHome && progress === 1) {
                    if (order.retourId) {
                        message = settings.deliveryNotHomeRetourMessage
                    } else {
                        message = settings.deliveryNotHomeRescheduleMessage
                    }
                }

                if (address.hasArrived || order.status === dbCodes.status.verwacht()) {
                    dateHeader = 'Je zending wordt bezorgd op'
                    displayDate = 'Datum nog niet bekend'
                    displayTime = ''
                } else {
                    if (!address.estimatedTimeOfArrival) {
                        dateHeader = 'Je zending wordt bezorgd op'
                        displayTime = ''
                        info = deliveryOptionsLine
                    }

                    if (address.estimatedTimeOfArrival || order.status === dbCodes.status.onderweg()) {
                        dateHeader = 'Je zending wordt bezorgd op'
                        displayTime = estimatedTimeOfArrival
                        info = deliveryOptionsLine
                    }
                }
            }

            if (order.status === dbCodes.status.inBewaring()) {
                message = settings.deliveryNotHomeOnHoldMessage
                dateHeader = 'We houden je zending in bewaring tot'
                displayDate = moment(order.date).locale('nl').format('dddd DD MMMM')
            } else if (order.status === dbCodes.status.geannuleerd()) {
                message = settings.deliveryCancelledMessage

                dateHeader = 'Je zending wordt bezorgd op'
                displayDate = 'Zending geannuleerd'
                displayTime = ''
            }
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh'}}>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', width: '100%', background: Colors.backgroundNeutral}}>
                    <Card
                        shadow='true'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '95%',
                            maxWidth: 900,
                            marginTop: 100,
                            marginBottom: 50,
                            padding: 24,
                            flex: 0
                        }}
                    >
                        <img
                            style={{maxWidth: 256, maxHeight: 128, objectFit: 'contain', marginBottom: 50}}
                            src={settings.logo || '/images/logoPlaceholder.png'} alt="Logo"
                        />

                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%'}}>
                            <StatusIcon
                                initial
                                active
                                icon='mdi mdi-laptop'
                                color={settings.color}
                            />

                            <StatusBar
                                active={progress > 1}
                                color={settings.color}
                            />

                            <StatusIcon
                                active={progress > 1}
                                icon='mdi mdi-map-clock'
                                color={settings.color}
                            />

                            <StatusBar
                                active={progress > 2}
                                color={settings.color}
                            />

                            <StatusIcon
                                active={progress > 2}
                                icon={statusIconMap[settings.outForDeliveryStatusIcon] || 'mdi mdi-bike'}
                                color={settings.color}
                            />

                            <StatusBar
                                active={progress > 3}
                                color={settings.color}
                            />

                            <StatusIcon
                                active={progress > 3}
                                icon='mdi mdi-home-outline'
                                color={settings.color}
                            />
                        </div>

                        {message &&
                            <Alert variant='warning' style={{marginTop: 24}}>{message}</Alert>
                        }


                        {info &&
                            <Alert variant='info'style={{marginTop: 24}}>{info}</Alert>
                        }

                        <div style={{width: '100%', background: Colors.backgroundNeutral2, padding: 20, marginTop: 48}}>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <div style={{flex: 1, minWidth: 250, marginBottom: 30}}>
                                    <h4 style={styles.h4}>{dateHeader}</h4>
                                    <h3 style={styles.h3}>{displayDate}</h3>
                                    <h3 style={styles.h3}>{displayTime}</h3>
                                </div>

                                <div style={{flex: 1, minWidth: 250}}>

                                    {address.hasArrived && order.signee &&
                                        <div style={{marginBottom: 12}}>
                                            <h4 style={styles.h4}>Ontvangen door</h4>
                                            <p style={styles.p}>{order.signee}</p>
                                        </div>
                                    }
                                    {address.hasArrived && order.signature &&
                                        <div style={{marginBottom: 12}}>
                                            <h4 style={{...styles.h4}}>Handtekening</h4>
                                            <img
                                                style={{height: 100, width: 120, objectFit: 'contain'}}
                                                src={order.signature}
                                            />
                                            <IconButton
                                                onClick={() => this.onClickViewImage(order.signature)}
                                                color={settings.color}
                                            >
                                                <i className='mdi mdi-magnify-plus-outline'/>
                                            </IconButton>
                                        </div>
                                    }

                                    {address.hasArrived && order.image &&
                                        <div>
                                            <h4 style={{...styles.h4, marginTop: 15}}>Foto</h4>
                                            <img
                                                style={{height: 100, width: 120, objectFit: 'contain'}}
                                                src={order.image}
                                            />
                                            <IconButton
                                                onClick={() => this.onClickViewImage(order.image)}
                                                color={settings.color}
                                            >
                                                <i className='mdi mdi-magnify-plus-outline'/>
                                            </IconButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {order.addressColli.length > 1 &&
                            <div style={{width: '100%', marginTop: 24}}>
                                <H6>Deze zending bestaat uit meerdere colli:</H6>
                                {order.addressColli.map((collo) => {
                                    return (
                                        <Row key={`collo${collo.index}`} style={{width: '100%', alignItems: 'center'}}>

                                            <P style={{marginRight: 12}}>{`${collo.index + 1}:`}</P>
                                            <Column>
                                                <P>{`${collo.barcode || 'Geen barcode'}${collo.type ? ` (${dbCodes.colloTypes[collo.type]})` : ''}`}</P>
                                            </Column>

                                            <Column style={{alignItems: 'center'}}>
                                                <P style={{marginRight: 12}}>
                                                    {['cancelled', 'notcollected', 'collected', 'notdelivered', 'missing', 'refused', 'manco', 'returned', 'delivered'].includes(collo.status) ?
                                                        dbCodes.colloStatus[collo.status === 'manco' ? 'notdelivered' : collo.status]:
                                                        ''
                                                    }
                                                </P>

                                                {['cancelled', 'notcollected', 'notdelivered', 'missing', 'refused', 'manco', 'returned'].includes(collo.status) &&
                                                    <i style={{fontSize: 12, color: Colors.errorBright}} className='mdi mdi-circle'/>
                                                }

                                                {['collected', 'delivered'].includes(collo.status) &&
                                                    <i style={{fontSize: 12, color: Colors.successBright}} className='mdi mdi-circle'/>
                                                }
                                            </Column>
                                        </Row>
                                    )
                                })}
                            </div>

                        }

                        <div style={{
                            display: 'flex',
                            width: '100%',
                            flexWrap: 'wrap',
                            background: Colors.backgroundNeutral2,
                            padding: 20,
                            marginTop: 24,
                            borderBottom: `solid 3px ${settings.color}`
                        }}
                        >
                            <div style={{flex: 1, minWidth: 300, marginBottom: 12}}>
                                <h4 style={styles.h4}><i style={{marginRight: 6}} className='mdi mdi-map-marker'/>{address.type === 'delivery' ? 'Bezorgadres' : 'Ophaaladres'}</h4>
                                <p style={styles.p}>{address.name}</p>
                                <p style={styles.p}>{address.attention}</p>
                                <p style={styles.p}>{`${address.street} ${address.nr}${address.addition}`}</p>
                                <p style={styles.p}>{address.street2}</p>
                                <p style={styles.p}>{`${address.postalCode} ${address.city}`}</p>
                                <p style={styles.p}>{address.country}</p>
                            </div>

                            <div style={{flex: 1}}>
                                <h4 style={styles.h4}>
                                    <i style={{marginRight: 6}} className='mdi mdi-store'/>
                                    Afzender
                                </h4>
                                <p style={styles.p}>{order.customerName}</p>

                                {order.reference &&
                                    <>
                                        <h4 style={{...styles.h4, marginTop: 15}}>
                                            <i style={{marginRight: 6}} className='mdi mdi-pound'/>
                                            Referentie
                                        </h4>
                                        <p style={styles.p}>{order.reference}</p>
                                    </>
                                }

                                <h4 style={{...styles.h4, marginTop: 15}}>
                                    <i style={{marginRight: 6}} className={'mdi mdi-barcode-scan'}/>
                                    Track & Trace
                                </h4>
                                <p style={styles.p}>{order.barcode || order.trackTrace}</p>
                            </div>
                        </div>


                    </Card>
                </div>

                <div style={{display: 'flex', justifyContent: 'center', background: Colors.backgroundNeutral2, width: '100%'}}>
                    <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', maxWidth: 1200}}>

                        <div style={{flex: 1, minWidth: 300, margin: 30}}>
                            <H5>{settings.footerTitle}</H5>
                            <P style={{marginBottom: 30}}>{settings.footerMessage}</P>


                            <S1 >{settings.companyAddress.name}</S1>
                            <P>{`${settings.companyAddress.street} ${settings.companyAddress.nr}${settings.companyAddress.addition}`}</P>
                            <P>{`${settings.companyAddress.postalCode} ${settings.companyAddress.city}`}</P>

                            {settings.phone &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6}} className='mdi mdi-phone'/>
                                    <a style={{textDecoration: 'none'}} href={`tel:${settings.phone}`}>
                                        <P>{settings.phone}</P>
                                    </a>
                                </div>
                            }

                            {settings.email &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6}} className='mdi mdi-email'/>
                                    <a style={{textDecoration: 'none'}} href={`mailto:${settings.email}?subject=Vraag over mijn zending: ${order.trackTrace}`}>
                                        <P>{settings.email}</P>
                                    </a>
                                </div>
                            }
                        </div>

                        {settings.showFaq && settings.faq?.length > 0 &&
                            <div style={{flex: 1, minWidth: 320, margin: 30}}>
                                <H5>Veelgestelde vragen</H5>

                                {settings.faq.map(({question, answer}, index) => {
                                    return <Question key={index} settings={settings} question={question} answer={answer}/>
                                })}
                            </div>
                        }
                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 24, background: Colors.backgroundNeutral2}}>
                    <img style={{objectFit: 'contain', width: 100}} src="/images/VELOYDLOGO.png" alt="Logo"/>

                    <div style={{display: 'flex', marginTop: 12}}>
                        <P>&copy; {moment().year()} Veloyd</P>
                        <P style={{marginLeft: 6, marginRight: 6}}>|</P>
                        <P><a style={{color: Colors.textDark}} target='_blank' href='https://veloyd.nl/privacy-policy/' rel="noreferrer">Privacy policy</a></P>
                    </div>
                </div>
            </div>
        )
    }
}

const StatusIcon = (props) => {
    return (
        <div style={{
            display: 'flex',
            flexShrink: 0,
            width: 50,
            height: 50,
            fontSize: 24,
            background: props.active ? props.color || Colors.brandColor : Colors.grey30,
            color: 'white',
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center'
        }}
        >
            <i className={props.icon}/>
        </div>
    )
}

const StatusBar = (props) => {
    return (
        <div
            style={{
                background: props.active ? props.color || Colors.brandColor : Colors.grey30,
                height: 2,
                flex: 1
            }}
        />
    )
}

class Question extends React.Component {
    constructor(props) {
        super(props)

        this.state = {showAnswer: false}
    }

    render() {
        const {showAnswer} = this.state
        const {question, answer} = this.props

        const defaultStyle = {
            marginBottom: 10
        }

        const questionStyle = {
            cursor: 'pointer',
            userSelect: 'none',
            color: Colors.textDark,
            marginBottom: 12
        }

        const answerStyle = {
            paddingTop: showAnswer ? 15 : 0,
            paddingBottom: showAnswer ? 15 : 0,
            paddingLeft: 15,
            paddingRight: 15,
            height: showAnswer ? 'auto' : 1,
            overflow: 'hidden',
            transition: 'all .3s ease',
            marginTop: showAnswer ? 15 : 0,
            marginBottom: showAnswer ? 15 : 0,
            color: Colors.textDark
        }

        return (
            <div style={defaultStyle} onClick={() => this.setState({showAnswer: !showAnswer})}>
                <S1 style={questionStyle}><i className={showAnswer ? 'mdi mdi-minus' : 'mdi mdi-plus'}/>{question}</S1>
                <P style={answerStyle}>{answer}</P>
            </div>
        )
    }
}

